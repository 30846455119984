import {useMemo} from 'react'
import {FilterInputContainer} from '../../../../../components/tables/advanced-filter-inputs/FilterInputContainer'
import {MultiSelectToggleFilterInput} from '../../../../../components/tables/advanced-filter-inputs/MultiSelectToggleFilterInput'
import {FilterType} from '../../../../../components/tables/constants/FilterType'
import {PortalSearchModel} from '../../../../../models/customer-portal/PortalSearchModel'
import {TicketGroupModel} from '../../../../../models/customer-portal/TicketModel'
import {TicketPortalModel} from '../../../../../models/ems/TicketModel'
import {FilterModel} from '../../../../../models/FilterModel'
import {TicketTableFlat} from './TicketTableFlat'
import {TicketTableGrouped} from './TicketTableGrouped'
import {FilterProductInput} from '../AdvancedFilter/FilterProductInput'
import {GlobalSearchModel} from '../../../../../models/GlobalSearchModel'
import {EventModel} from '../../../../../models/ems/EventModel'
import {TimeSlotFormatter} from '../../../../default/ems/components/tables/column-formatters/TimeSlotFormatter'
import {PortalTicketProductType} from '../PortalTicketProductType'
import {ColumnStyle} from '../../../../../components/tables/constants/ColumnStyle'
import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
import {PortalShareStatusColumn} from '../PortalShareStatusColumn'
import {TicketCodeColumnForBooking} from '../TicketCodeColumnForBooking'
import {TicketLocationCodeColumn} from '../TicketLocationCodeColumn'
import {FromProp} from '../BookingProductTable/CustomerProductBookingDetailTable'
import {CustomerModel} from '../../../../../models/CustomerModel'

export interface TicketTableProps {
  data?: GlobalSearchModel<TicketPortalModel> | PortalSearchModel<TicketGroupModel>
  onFilter: (filter: FilterModel) => void
  onRefresh: () => void
  onRefreshCallback?: () => void
  event?: EventModel
  initialFilters?: FilterModel
  bookingProductCode?: string
  toPath?: FromProp
  customer?: CustomerModel
}

export const TicketTable = ({
  data,
  onFilter,
  onRefresh,
  onRefreshCallback,
  event,
  initialFilters,
  bookingProductCode,
  toPath = 'tickets',
  customer,
}: TicketTableProps) => {
  const eventTimezone = useMemo(() => {
    if (event){
      return event?.venue?.timezone?.name
    }
  }, [])

  const tableDataFlat = useMemo((): TicketPortalModel[] | TicketGroupModel => {
    if (data) {
      if (Array.isArray(data.data)) return data.data
      else {
        return Object.entries(data.data).reduce<TicketPortalModel[]>((acc, [, tickets]) => {
          tickets.forEach((ticket) => {
            acc.push(ticket)
          })
          return acc
        }, [])
      }
    }
    return []
  }, [data])

  const columns = useMemo(() => {
    const columns: TableColumnOptions<TicketPortalModel>[] = [
      {
        field: 'code',
        label: 'Code',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.CODE,
        dataExtract: (data) => data.code,
      },
      {
        field: 'bookingCode',
        label: 'Booking',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.CODE,
        render: ({data}) => {
          if (!bookingProductCode) return TicketCodeColumnForBooking({data, event, customer})
          return <p> {data.bookingCode} </p>
        },
      },
      {
        field: 'customerName',
        label: 'Customer',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.CODE,
        dataExtract: (data) => data.customerName,
      },

      {
        field: 'locationName',
        label: 'Location',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
        render: ({data}) => TicketLocationCodeColumn({data, event, toPath, customer}),
      },

      {
        field: 'seatRow',
        label: 'Row',
        hideable: true,
        sortable: true,
      },
      {
        field: 'seatNo',
        label: 'Seat',
        hideable: true,
        sortable: true,
      },

      {
        field: 'productName',
        label: 'Product',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
        dataExtract: (data) => data.productName,
      },

      {
        field: 'productType',
        label: 'Type',
        sortable: true,
        hideable: true,
        render: ({data}) => <PortalTicketProductType data={data} />,
      },
      {
        field: 'startedAt',
        label: 'Start Date',
        sortable: true,
        hideable: true,
        render: ({data}) => (
          <TimeSlotFormatter isTimeslot={data.isTimeslot} timezone={event ? eventTimezone : ''}>{data.startedAt}</TimeSlotFormatter>
        ),
      },
      {
        field: 'endedAt',
        label: 'End Date',
        sortable: true,
        hideable: true,
        render: ({data}) => (
          <TimeSlotFormatter isTimeslot={data.isTimeslot} timezone={event ? eventTimezone : ''}>{data.endedAt}</TimeSlotFormatter>
        ),
      },
      {
        field: 'status',
        label: 'Status',
        sortable: true,
        hideable: true,
        render: ({data}) => <PortalShareStatusColumn data={data} />,
      },
    ]

    if (customer) {
      columns.splice(3, 0, {
        field: 'eventName',
        label: 'Event',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
        dataExtract: (data) => data.eventName,
      })
    }
    return columns
  }, [bookingProductCode, event, toPath, customer])

  return (
    <>
      {Array.isArray(tableDataFlat) ? (
        <TicketTableFlat
          onFilter={onFilter}
          onRefreshCallback={onRefreshCallback}
          onRefresh={onRefresh}
          data={tableDataFlat}
          total={data?.total}
          columns={columns}
          initialFilters={initialFilters}
          advancedFilters={
            <FilterInputContainer>
              <FilterProductInput
                field='productCode'
                label='Product'
                placeholder='Filter Product'
              />
              <MultiSelectToggleFilterInput
                field='status'
                items={FilterType.TICKET_VOUCHER_PORTAL_STATUS}
                label='Status'
              />
              <MultiSelectToggleFilterInput
                field='type'
                items={FilterType.PRODUCT_TYPE}
                label='Type'
              />
            </FilterInputContainer>
          }
        />
      ) : (
        <TicketTableGrouped
          onRefreshCallback={onRefreshCallback}
          onFilter={onFilter}
          onRefresh={onRefresh}
          data={tableDataFlat}
          total={data?.total}
          columnsFlat={columns}
          initialFilters={initialFilters}
          advancedFilters={
            <FilterInputContainer>
              <FilterProductInput
                field='productCode'
                label='Product'
                placeholder='Filter Product'
              />
              <MultiSelectToggleFilterInput
                field='status'
                items={FilterType.TICKET_VOUCHER_PORTAL_STATUS}
                label='Status'
              />
              <MultiSelectToggleFilterInput
                field='type'
                items={FilterType.PRODUCT_TYPE}
                label='Type'
              />
            </FilterInputContainer>
          }
        />
      )}
    </>
  )
}
