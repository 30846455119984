import {useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {actions, TableOption} from '../../modules/default/system/redux/SystemRedux'
import {useOnMount} from '../hooks/useOnMount'

export interface UseTableOptionOptions {
  tableName?: string
  defaults?: Partial<TableOption>
}

export interface UseTableOptionData {
  hiddenColumns: string[]
  setHiddenColumns: (hiddenColumns: string[]) => void
}

export const useTableOptions = ({
  tableName,
  defaults,
}: UseTableOptionOptions): UseTableOptionData => {
  const dispatch = useDispatch()
  const tableOptions = useSelector((state: RootState) => {
    if (tableName) {
      return state.system.tableOptions[tableName]
    }
    return null
  })

  const setHiddenColumns = useCallback(
    (hiddenColumns: string[]) => {
      if (tableName) {
        dispatch(
          actions.setTableOptions(tableName, {
            ...tableOptions,
            hiddenColumns,
          })
        )
      }
    },
    [dispatch, tableName, tableOptions]
  )

  useOnMount(() => {
    // if (!tableOptions) {
    //   if (defaults?.hiddenColumns) {
    //     setHiddenColumns(defaults.hiddenColumns)
    //   }
    // }
    if (defaults?.hiddenColumns) {
      setHiddenColumns(defaults.hiddenColumns)
    }
  })

  const hiddenColumns = useMemo((): string[] => {
    return tableOptions?.hiddenColumns || []
  }, [tableOptions?.hiddenColumns])

  return {
    setHiddenColumns,
    hiddenColumns,
  }
}
